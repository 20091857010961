<!-- eslint-disable vue/no-unused-vars -->
<template>
  <div>
    <validation-observer ref="simpleRules">
      <button
        class="btn btn-gradient-primary mb-1"
        @click="Submit()"
      >
        บันทึกข้อมูล
      </button>
      <b-row class="match-height">
        <b-col md="6">
          <div class="card">
            <div class="header-cardx p-2">
              <h3 class="mb-0">
                ข้อมูลประเภทจัดส่ง
              </h3>
            </div>

            <div class="p-2">
              <b-row>
                <b-col md="12">
                  <b-form-group
                    label-for="h-product-name"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ชื่อ :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="Name"
                      rules="required"
                    >
                      <b-form-input
                        id="h-product-name"
                        v-model="name"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-service-fee"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ค่าบริการ :
                    </template>
                    <validation-provider
                      #default="{ errors }"
                      name="service Fee"
                      rules="required"
                    >
                      <b-form-input
                        id="h-service-fee"
                        v-model="serviceFee"
                        :state="errors.length > 0 ? false:null"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-printCoverSheet"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      พิมพ์ใบปะหน้า :
                    </template>

                    <b-form-checkbox
                      id="h-printCoverSheet"
                      v-model="printCoverSheet"
                      value="1"
                      class="mt-50"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-payShippingOrigin"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      จ่ายค่าขนส่งต้นทาง :
                    </template>
                    <b-form-checkbox
                      id="h-payShippingOrigin"
                      v-model="payShippingOrigin"
                      name="check-buttons"
                      switch
                      inline
                      class="mt-50"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-payDeliveryFee"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      จ่ายค่าขนส่งปลายทาง :
                    </template>
                    <b-form-checkbox
                      id="h-payDeliveryFee"
                      v-model="payDeliveryFee"
                      name="check-button"
                      switch
                      inline
                      class="mt-50"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-addr"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      คำอธิบาย :
                    </template>
                    <b-form-textarea
                      id="h-addr"
                      v-model="addr"
                      type="text"
                    />
                  </b-form-group>
                </b-col>

                <b-col md="12">
                  <b-form-group
                    label-for="h-type"
                    label-cols-md="3"
                  >
                    <template v-slot:label>
                      ประเภท :
                    </template>

                    <div>
                      <b-form-radio
                        v-model="Selected"
                        name="some-radio9"
                        value="e"
                        class="custom-control-primary mb-50 mt-50"
                      >
                        ตามขนาด (กิโลกรัม)
                      </b-form-radio>

                      <b-form-radio
                        v-model="Selected"
                        name="some-radio9"
                        value="e"
                        class="custom-control-primary"
                      >
                        ตามขนาด (คิวบิกเมตร)
                      </b-form-radio>
                    </div>
                  </b-form-group>
                </b-col>
              </b-row>
            </div>
          </div>
        </b-col>

        <b-col md="6">
          <div
            class="card"
          >
            <div class="header-cardx p-2">
              <div class="d-flex align-items-center justify-content-between">
                <h3 class="mb-0">
                  การตั้งราคา
                </h3>

                <a
                  href="javascript:void(0)"
                  @click="addRow()"
                ><i class="fal fa-plus" /> เพิ่มแถว</a>
              </div>
            </div>

            <div class="pl-2 pr-2">
              <b-table
                responsive="sm"
                :fields="fields"
                :items="items"
                class="mb-2"
                show-empty
              >

                <template #empty>
                  <div class="text-center">
                    <img
                      src="@/assets/images/anan-img/empty/empty.png"
                      alt="empty"
                      width="180px"
                    >
                    <p>No Data</p>
                  </div>
                </template>

                <template #cell(minimum)="data">
                  <b-form-input
                    v-model="data.item.minimum"
                    type="number"
                    class="text-center"
                  />
                </template>

                <template #cell(price)="data">
                  <b-form-input
                    v-model="data.item.price"
                    type="number"
                    class="text-center"
                  />
                </template>

                <template #cell(action)="data">
                  <feather-icon
                    icon="TrashIcon"
                    class="text-danger cursor-pointer"
                    @click="removeRow(data.index)"
                  />
                </template>
              </b-table>
            </div>

          </div>
        </b-col>
      </b-row>
    </validation-observer>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormInput,
  BFormGroup,
  BFormTextarea,
  BTable,
  BFormRadio,
  BFormCheckbox,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    ValidationProvider,
    ValidationObserver,
    BFormInput,
    BFormGroup,
    BFormTextarea,
    BTable,
    BFormRadio,
    BFormCheckbox,
  },
  data() {
    return {
      required,
      file: null,
      fullname: null,
      tel: null,
      tel2: null,
      different: null,
      invited_by: null,
      role: null,
      username: null,
      password: null,
      sub_role: [],
      Address: [],

      // Add Address
      name: null,
      phone: null,
      address: null,
      province: null,
      district: null,
      subdistrict: null,
      postalcode: null,

      selectedFiles: [],
      uploadedImages: [],
      fields: [
        {
          key: 'minimum', label: 'ขั้นตำ', sortable: false, thStyle: { width: '25%' }, thClass: 'text-center', tdClass: 'text-center',
        },
        {
          key: 'price', label: 'ราคา', sortable: false, thStyle: { width: '70%' }, thClass: 'text-center',
        },
        {
          key: 'action', label: 'จัดการ', sortable: false, thStyle: { width: '5%' }, thClass: 'text-center', tdClass: 'text-right',
        },
      ],
      items: [],
      subrole: [],
      module_list: [],
      module_use: '',
      group_user_list: [],
      group_use: '',
      group_user_type: 1,
      group_userloading: false,
      category: '',
    }
  },
  computed: {
    passwordToggleIcon() {
      return this.passwordFieldType === 'password'
        ? 'EyeIcon'
        : 'EyeOffIcon'
    },
  },
  mounted() {
    this.sub_role = []
    this.GetSubrole()
    this.GetModuleList()
    this.GetGroupUserList()
  },
  methods: {
    addRow() {
      const newRow = {
        minimum: 0,
        price: 0,
      }
      this.items.push(newRow)
    },
    removeRow(index) {
      this.items.splice(index, 1)
    },
    async GetGroupUserList() {
      try {
        this.group_userloading = true
        const { data: res } = await this.$http.get('/groupbyuser/list/index')
        this.group_user_list = res
        this.group_userloading = false
      } catch (error) {
        console.log(error)
      }
    },
    async GetModuleList() {
      try {
        const { data: res } = await this.$http.get('/modulelist/index')
        this.module_list = res
      } catch (error) {
        console.log(error)
      }
    },
    async GetSubrole() {
      try {
        const { data: res } = await this.$http.get('/moduledetail/index')
        this.subrole = res
        this.subrole.forEach(item => {
          this.$set(item, 'status', false)
        })
      } catch (error) {
        console.log(error)
      }
    },
    saveParcel() {
      this.$refs.addRules.validate().then(success => {
        if (success) {
          const Obj = {
            fullname: this.name,
            tel: this.phone,
            address: this.address,
            province: this.province,
            district: this.district,
            subdistrict: this.subdistrict,
            postalcode: this.postalcode,
          }
          this.Address.push(Obj)
          this.$bvModal.hide('add-addr')
        }
      })
    },
    SubRole(data) {
      this.sub_role = this.sub_role.filter(item => item !== data.level)
      if (data.status) {
        this.sub_role.push(data.level)
      } else {
        this.sub_role = this.sub_role.filter(item => item !== data.level)
      }
    },
    Submit() {
      this.$refs.simpleRules.validate().then(success => {
        if (success && this.group_use) {
          const subroleJs = this.subrole.filter(item => item.status)
          const subrole = subroleJs.map(item => item.level)
          const formData = {
            role: this.role.value,
            email: this.email,
            fullname: this.fullname,
            tel: this.tel,
            tel2: this.tel2,
            different: this.different,
            invited_by: this.invited_by,
            username: this.username,
            password: this.password,
            // sub_role: this.sub_role,
            sub_role: subrole.toString(),
            module_id: this.module_use.id,
            Address: this.Address,
            group_use: this.group_use.id,
            group_user_type: this.group_user_type,
            itemsCar: this.itemsCar,
            itemsShip: this.itemsShip,
          }
          // console.log(formData)
          this.$http
            .post('/users/Adminstore', formData)
            .then(() => {
              this.Success('เพิ่มผู้ใช้งาน')
              this.role = null
              this.email = null
              this.fullname = null
              this.tel = null
              this.tel2 = null
              this.different = null
              this.invited_by = null
              this.username = null
              this.password = null
              this.$router.push({ name: 'admin-employee' })
            })
            .catch(error => {
              this.SwalError(error.response.data.message)
            })
        }
      })
    },
    CalModule() {
      const moduleArr = this.module_use.sub_role.split(',')
      this.subrole.forEach(item => {
        if (moduleArr.includes(item.level.toString())) {
          this.$set(item, 'status', true)
        } else {
          this.$set(item, 'status', false)
        }
      })
    },
    CalGroupUser() {
      if (this.group_use) {
        // eslint-disable-next-line
          for (const Index in this.itemsCar) {
          this.itemsCar[Index].kg = this.group_use[this.itemsCar[Index].key_kg]
          this.itemsCar[Index].queue = this.group_use[this.itemsCar[Index].key_q]
        }
        // eslint-disable-next-line
          for (const Index in this.itemsShip) {
          this.itemsShip[Index].kg = this.group_use[this.itemsShip[Index].key_kg]
          this.itemsShip[Index].queue = this.group_use[this.itemsShip[Index].key_q]
        }
      }
    },
    Success(mes) {
      this.$swal({
        icon: 'success',
        // title: '<h3 style="color: #141414">ทำรายการสำเร็จ</h3>',
        text: mes,
        customClass: {
          confirmButton: 'btn btn-success',
        },
      })
    },
    SwalError(mes) {
      this.$swal({
        icon: 'error',
        title: `<h3 style="color: #141414">${this.$t('key-298')}</h3>`,
        text: mes,
        customClass: {
          confirmButton: 'btn btn-primary',
        },
      })
    },
    showAdd() {
      this.$bvModal.show('add-addr')
    },
    showModal() {
      this.name = null
      this.phone = null
      this.address = null
      this.province = null
      this.district = null
      this.subdistrict = null
      this.postalcode = null
      this.$bvModal.show('modal-1')
    },
  },
}
</script>

  <style lang="scss">
  @import "@core/scss/vue/libs/vue-select.scss";
  </style>
